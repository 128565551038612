@import '../../variable.css';

.btn_custom {
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    color: var(--nav-blue) !important;
    border-color: var(--nav-blue) !important;
    height: 30px !important;
    background-color: transparent;
}

.btn_custom:hover {
    background-color: var(--lite-grey) !important;
}

.btn_custom:focus {
    border-color: var(--white) !important;
    background-color: var(--white) !important;
    color: var(--black) !important;
    outline: 2px solid var(--black) !important;
}

.MuiInputLabel-root {
    margin: 0px !important;
}

.MuiInputAdornment-root {
    background-color: var(--nav-blue);
    width: 45px;
}

.MuiInputAdornment-root:hover {
    background-color: var(--nav-hover) !important;
}

.MuiInputBase-root {
    width: max-content;
}

.studentNameCls:hover {
    color: #363636 !important;
}
.custom_link{
    color: inherit !important;
    cursor: pointer;
    text-decoration: underline !important;
}

.studentReportClass {
    max-width: 100%;
}

.studentRep_lbl{
    padding: 10px 25px 0px 0px !important;
}
.studentRep_sub_text{    
    padding: 5px 25px 25px 0px;    
 }
.stu_download{
    padding: 25px 12px 5px 0px !important;
}