.bannerStyle {
    background-color: #FCF8F3;
    border: 1px solid #BD4F00;
    color: #BD4F00;
    text-align: justify;
}

.bannerStyle p {
    font-size: 16px;
    color: #BD4F00;
    line-height: 1.5rem;
    margin-bottom: 0px;
}

.bannerStyle a {
    font-size: 16px !important;
    color: #BD4F00 !important;
    text-decoration: underline solid !important;
}