.na{
    top: -7px;
    left: calc(50% + 5px);
    width: 28px;
    height: 28px;
    position: absolute;
    background: #F7F7F7;
    border-radius: 50%;
}
.page-center{
    padding: 130px;
}
.text{
    text-align: center;
    width: 35%;
    margin: auto;
}
.noAccess_text{
    color: var(--title_black);
    font-weight: 600;
    font-size: 1.75em;
}