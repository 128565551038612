@import './../variable.css';
.title-divider{
    border-color: darkgray !important;
}
@media (min-device-width:768px){
    .logo_w_resize{
        min-width: 232px;
        border-right: 1px solid lightgray;
        margin-top: 10px;
        margin-right: 15px;
    }
    .scroll_sticky{
        top: 56px !important;
    }
}
.header{
    background-color:var(--white) !important;
    color: #212121 !important;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding: 8px 0;
    flex-grow: 1;
    box-shadow: 0 2px 4px 0px rgba(0,0,0,0.08) !important;
    border-bottom: 1px solid var(--grey-bg);
    position: sticky;
    z-index: 1100;
    top: 0;
    height: 70px;
}

@media (max-device-width:768px){
    .scroll_sticky{
        top: 56px !important;
    }
    .logo_width{
        border-right: 1px solid lightgray;
        margin-top: 10px;
        margin-right: 15px;
    }
    .logo_w_resize{
        border-right: 1px solid lightgray;
        margin-top: 10px;
        margin-right: 15px;
    }   
}
@media (min-device-width:769px) and (max-device-width:1023px){    
    .logo_w_resize{
        min-width: 232px;
        border-right: 1px solid lightgray;
        margin-top: 10px;
        margin-right: 15px;
    }
    .logo_width{
        min-width: 232px;
        border-right: 1px solid lightgray;
        margin-top: 10px;
        margin-right: 15px;
    }
}
@media (max-device-width:426px){
    .logo_width{
        min-width: 190px;
        border-right: 1px solid lightgray;
        margin-top: 10px;
        margin-right: 15px;
    }
}
@media (min-device-width:1024px){
    .logo_width{
        max-width: 256px;
        margin-top: 10px;
    }
}
.reportText{
    font-weight: 600 !important;
}
.MuiList-root > button {
    display: none !important;
}
.MuiList-root{
    padding: 0px !important;
}
span + div{
    margin-left: 20px !important;
}
[data-testid="NotificationsOutlinedIcon"]{
    color: var(--bell-gray) !important;
    cursor: pointer;
}
.btn-notifi{
    border: none;
    background-color: transparent;
    padding: 0px;
}
/* .MuiBox-root{
    float: right;
} */
.title{
    margin: 0;
    font-family: Lato;
    color: #212121;
    line-height: 22px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0em;
}

