@import '../../variable.css';

div.select-input {
    width: auto;
}

.h_label{
    font-size: 1rem;
    color: var(--title_black);
    font-weight: 600;
    line-height: 1.125;
}

.h_txt_lbl{
    padding: 0px 25px 5px 0px !important;
}