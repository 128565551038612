.schInsightYearCls{
    color: #666;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.125rem;
}
.schInsightLevelCls{
    font-size: 16px;
    font-weight: 600;
    color: #363636;
    margin-bottom: 5px;
}
.viewReport{
    font-size: 13px !important;
    color: #485FC7 !important;
    font-weight: 500;
    cursor: pointer;
}
.viewReport:hover{
    border-bottom: 1px solid #485fc7;
}
.courseCls{
    font-size: 12px;
    text-align: start;
    white-space: nowrap;
    vertical-align: middle;
}
.chartRowCls{
    height: 25px !important;
    padding:3px 0px 0px 0px;
}
.chartCls{
    height: 125px;
    max-height: 125px;
    overflow-y: auto;
    padding: auto;
}
.chartCls:first-child {
    border:1px solid #dbdbdb;
}
.courseBarCls{
    height: 20px;
}
.barCls{
    height: 92% !important;
}

