@import "../../../variable.css";

.stu-header {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.125;
    color: var(--title_black);
    text-transform: uppercase;
}

.tData_lbl {
    padding: 10px 25px 0px 0px !important;
}

.tData_sub_text {
    padding: 10px 25px 0px 0px;
}

.teach_txt{
    color: var(--gray-800);
    clear: both;
    text-align: justify;
    font-size: 1rem;
    font-weight: 400;
}

.avatar {
    background-color: transparent !important;
    border: 1px solid var(--av-grey);
    color: var(--gray-800) !important;
}

.avatar-head {
    font-size: 1.25rem;
    color: var(--title_black);
    font-weight: 600;
    line-height: 1.125;
}

.notificationTechCls {
    background-color: #fff !important;
    border: 1px solid #808080 !important;
    margin: 0 2rem 1rem 0;
}

.uploadedTimeLable {
    text-align: start;
    background-color: #ffe08a;
    border-radius: 4px;
    padding: 5px 10px;
    width: max-content;
}

.bannerCls .cursor-pointer {
        cursor: pointer;
}

#teach_guidance_doc{
    color:var(--nav-blue) !important;
}

.invalid_error{
    color: var(--error) !important;
}

.teach_update_time{
    line-height: 2;
}