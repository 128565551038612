@import '../../variable.css';

.sub_txt{
    font-size: 1.175rem;
    color: var(--gray-800);
    font-weight: 400;
    clear: both;
}
.lbl_txt{
    font-weight: 700;
    float: left;
    padding: 0px 25px 0px 0px;
    font-size: 1.5rem;
}
.teacherRep_sub_text{    
    padding: 0px 25px 25px 0px;    
 }
 .lbl_teach{
   padding: 10px 25px 0px 0px !important;
 }
.teach_download{
    padding: 25px 12px 5px 0px !important;
}