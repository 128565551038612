@import '../../variable.css';
.card_size{
    background-color: #fff;
    border-radius: 6px;
    margin: 32px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
    padding: 1.25rem;
    fill: var(--nav-blue);
}
.ml_p_inherit{
    margin-left: inherit;
    margin-right: auto;
    padding-left: inherit;
    padding-right: inherit;
}
.m_12{
    margin: 12px;
}
a{
    text-decoration: none !important;
    color: var(--card_black) !important;
}
.card_size:hover{
    border:1px solid #485fc7;
    cursor: pointer;
}
a div svg{
    margin-right: 10px;
}
.link{
    text-decoration: underline !important;
}
#dataLinks{
    color: inherit !important;
    text-decoration: underline !important;
    cursor: pointer;
}
.dash_lbl{
    padding: 10px 20px 0px 0px !important;
}
.dash_sub_text{
    padding: 5px 25px 25px 10px;
}
.online_lbl{
    padding: 0px 25px 5px 0px !important;
}
.overview{
    padding-right: 35px !important;
}
.online_state_lbl{
    padding: 0px 25px 5px 0px !important;
}
.enhance_m{
    margin-left: 0.8rem !important;
    margin-right: 0.5rem;
}
.m_shortLinks{
    margin-top: -12px !important;
}