@media (max-width:768px){
    .candId{
        width: 30%;
        max-width: 30%;
    }
    .name{
        width: 30%;
        max-width: 30%;    
    }
    .grade{
        width: 40%;
        max-width: 40%;
    }
}

@media (min-width:769px) and (max-width:820px){
    .candId{
        width: 25%;
        max-width: 25%;
    }
    .name{
        width: 30%;
        max-width: 30%;    
    }
    .grade{
        width: 45%;
        max-width: 45%;
    }
}

@media (min-width:821px) and (max-width:991px){
    .candId{
        width: 20%;
        max-width: 20%;
    }
    .name{
        width: 30%;
        max-width: 30%;    
    }
    .grade{
        width: 50%;
        max-width: 50%;
    }
}

@media (min-width:991px){
    .candId{
        width: 15%;
        max-width: 15%;
    }
    .name{
        width: 30%;
        max-width: 30%;    
    }
    .grade{
        width: 55%;
        max-width: 55%;
    }
}

.stu_col_width{
    width: 200px;
    max-width: 200px;
}