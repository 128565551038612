@import '../../variable.css';

.head_bg_yellow{
    background-color: var(--header-yellow);
}
.val_bg_yellow{
    background-color: var(--value-yellow);
}
.head_bg_blue{
    background-color: var(--header-blue) !important;
}
.val_bg_blue{
    background-color: var(--value-blue);
}
.table_bg{
    border: 1px solid var(--grey-bg);
    border-radius: 6px;
    background-color: var(--white);
    margin-left: 12px;
}
.t_border_none{
    border-bottom: none !important;
}
.d_contents{
    display: contents;
}
.s_align{
    margin-left: 12px !important;
}
.m_width{
    min-width: 65px;
    max-width: 65px;
}
.w_grade{
    min-width: 20px;
    max-width: 20px;
}