:root{
    --header-yellow: #f6cd00;
    --value-yellow: rgba(246, 205, 0, 0.25);
    --header-blue:#3e8ed0 !important;
    --value-blue:rgba(62, 142, 208, 0.25);

    --dark-grey: #a9a9a9;
    --grey: #f5f5f5;
    --grey-color: #767676;
    --gray-800: #4a4a4a;
    --grey-bg:#E0E0E0;
    --grey-text:#424242;
    --lite-grey:#E9E9E9;
    --grey-tip:#7a7a7a;
    --label-color:#666;
    --card-border:#D0D0D0;
    --av-grey:#CCC;

    --white: #fff;

    --black: #000;  
    --title_black: #363636;
    --card_black: #4a4a4a;

    --a-color:#485fc7;
    --nav-blue:#0076BD;
    --nav-hover:#0064A6;
    --bell-gray:#778899;

    --purple:#AB04c7;
    --orng:#FCFF52;
    
    --error: #D9221C;
    
}