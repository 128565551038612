@import '../../../variable.css';

.divider {
    border-right: 1px solid var(--grey-bg);
}

.divider_resp{
    border-bottom: 1px solid var(--grey-bg);
}

.cardCustom {
    background-color: var(--white);
    border-radius: 0.25em;
    color: var(--gray-800);
    max-width: 100%;
    max-height: fit-content;
    border: 1px solid var(--grey-bg);
}

.centre {
    color: var(--title_black);
    font-size: 1em;
    font-weight: 600;
    line-height: 1.125;
}

.tip {
    font-size: 0.75rem;
    color: var(--grey-tip);
}

.district_lbl {
    padding-left: 0px !important;
}

.districtCls {
    border: 0px !important;
    background-color: #ffffff !important;
    text-align: start;
}

.districtCls a {
    text-decoration: none !important;
    font-size: 12px;
    font-weight: normal !important;
}