@import '../../common/global.css';

.lbl{
    font-weight: 700 !important;    
    float: left;
}
.new_user_lbl{
    padding: 25px 25px 0px 0px !important;
}
.new_fs{
    font-size: 1.175rem !important;
}
.ml-35{
    margin-left: 35px !important;
}
.card-head{
    margin: 15px 17px 0px;
    font-size: 1.175rem;
    font-weight: 400;
    color: var(--title_black);
}
.ml-15{
    margin-left: 15px;
}
.card-body-text{
    color: var(--grey-text);
}
.card_body{
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02) !important;
}
#termsAcceptance{
    cursor: pointer;
}
a.link{
    color: var(--a-color) !important;
}
.btn:disabled{
    pointer-events: unset !important;
    cursor: not-allowed !important;
}