@import '../variable.css';

.txt{
    padding: 25px 25px 5px 0px;
    float: left;
}
.lbl{
    color: var(--title_black);
    font-size: 1.5rem;
    font-weight: 600;
    vertical-align: text-top;
}
.sub_text{
    color: var(--gray-800);
    clear: both;
    text-align: justify;
    font-size: 1rem;
    font-weight: 400;
 }
.card_spacing{
    padding: 1.5rem;
    border: 1px solid var(--card-border) !important;
}
.main_label, .textfield-label, .MuiInputLabel-root{
    color:var(--label-color) !important;
    font-family: 'Source Sans 3', BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,
                "Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    letter-spacing: 0.125em !important;
    text-transform: uppercase;
    margin:-5px 0px -5px 18px;
}
.Mui-selected>div.MuiListItemIcon-root{
    fill: var(--nav-blue) !important;
    min-width: 25px !important;
}
.MuiListItemIcon-root{
    fill: var(--grey-color) !important;
    min-width: 25px !important;
}
[data-testid="SearchRoundedIcon"]{
    fill: var(--white) !important;
}
.studentNameCls{
    font-size: 15px !important;
    font-weight: 700 !important;
    color: var(--a-color) !important;
    word-break: break-all;
    font-family: sans-serif !important;
}