@import './variable.css';

.App {
  text-align: center;
}



.display {
  max-width: 100dvw;
  display: flex;
  align-items: stretch !important;
}

.content {
  box-sizing: border-box;
  max-width: 100dvw;
  overflow: hidden;
  flex: 1;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.side_nav {
  border-right: 1px solid #E0E0E0 !important;
  background-color: var(--white);
  min-height: 100vh;
  min-width: 256px;
  max-width: 256px;
}

.MuiToolbar-root {
  min-height: 70px !important;
}

.MuiToolbar-root .title-divider {
  margin: 0px 25px 0px 41px !important;
}

html,
body,
.display {
  min-height: 100% !important;
}
.support{
  border: 6px solid var(--purple);  
}
.support_top{
  background-color: var(--purple);
  color: var(--white);
  display: flex;
}
.emulate_text{
  color: var(--orng);
  font-size: 1rem;
  margin-left: 25px;
  font-weight: 600;
  line-height: 1.125;
}
.eml_subtxt{
  font-size: 0.75rem;
  margin-left: 25px;
}
.eml_subtxt_main{
  color: var(--white);
}
.eml_btn{
  color: var(--white) !important;
  background-color: transparent !important;
  border-color: var(--white) !important;
  font-size: 0.75rem !important;
}
.eml_btn_align{
  margin-right: 15px !important;
}
.s_centre{
  color: var(--white);
}

.banner_column {
  flex: 50%;
}

@media (max-width:768px) {
  .selectCentre {
    position: fixed;
    top: 0px;
  }
  .menu_btn_mt{
    margin-top: 80px;
  }
  .display {
    width: 100dvw !important;
    min-height: 100dvh !important;
  }
  .side_nav_comp{
    width: 100vw !important;
    height: 100vh !important;
    position: fixed;
    z-index: 9999;
    background-color: var(--white);
    top: 0;
    left: 0;
    visibility: visible;
  }
  #side_menu{
    display: none;
  }
  #side_menu_btn{
    display: block;
  }
  .menu_button{
    background-color: var(--title_black);
    border-color: transparent;
    color: var(--white);
    border-radius: 4px;
  }
}

@media (min-width:769px){
  #side_menu{
    display: block;
  }
  #side_menu_btn{
    display: none;
  }
  .selectCentre {
    position: fixed;
    top: 0px;
  }
}