@import '../../variable.css';

.fg_bg_color{
    background-color: #EFF5FB;
}
.MuiTableCell-root{
    padding: 10px !important;
}
.col_odd_bg{
    background-color: #f5f5f5 !important;
}
.MuiTableHead-root>tr>th{
    font-weight: 600 !important;
}
.card-header{
    font-size: 1.25rem;
    font-weight: 600;
    color: #363636 !important;
}
.card-sub-head{
    color: var(--gray-800) !important;
}
.col_width{
    width: 300px;
    max-width: 300px;
}
 .tot_col_width{
    width: 100px;
    max-width: 100px;
}
.comp_col_width{
    width: 70px;
    max-width: 70px;
}
.h_max_width{
    width: 70px;
    max-width: 70px;
}
.MuiPaper-root > ul {
    max-height: 250px !important;
}
.syllabus_lbl{
    padding: 10px 25px 0px 0px !important;
}
.syllabus_sub_text{
    padding: 5px 25px 25px 0px !important;
 }
.syl_download{
    padding: 25px 12px 5px 0px !important;
}