@import '../variable.css';
hr{
    background-color: var(--grey-bg) !important;
    height: 0;
    margin: 10px 0px;
}
.centre_text{
    margin:-5px 0px -5px 18px;
}
.txt_captialize{
    text-transform: capitalize !important;
}
.support_line{
    background-color: var(--grey-bg) !important;
    height: 2px;
}