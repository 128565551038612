@import '../../variable.css';

.tag {
    /* background-color: #f5f5f5; */
    border-radius: 4px;
    color: #4a4a4a;
    font-size: 12px;
    display: inline-block;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    /* height: 24px; */
    /* margin: 0px 5px;
    padding: 10px; */
}

.sub-text {
    text-align: start;
}
.studentName {
    font-size: 18px;
    font-weight: 700;
    color: #363636 !important;
    text-align: start;
    word-break: break-all;
    font-family: sans-serif;
}

.backIconcls {
    margin: 0px !important;
    fill: #0076BD !important;
}

.backBtnCls {
    padding-left: 0px !important;
    padding-right: 8px !important;
}

.schoolCls {
    font-size: 16px !important;
    font-weight: 400;
    text-align: left;
}

.studentDetail_lbl{
    padding: 10px 25px 0px 0px !important;
}
.downloadBtn{
    padding: 10px;
}
.btns_txt{
    display: flex;
}
.names_txt{
    word-break: break-all;
}
.ml-5{
    margin-left: 10px !important;
}
.head_align{
    padding: 25px 0px 0px 0px;
}